<template>
  <section class="section has-background-eurotext-light has-text-white-bis">
    <div class="container">
      <div v-for="ws in webSockets" :key="ws.className">
        <div class="columns is-vcentered">
          <div class="column" style="text-align: right">
            <span>{{ ws.classLabel }}</span>
          </div>
          <div class="column">
            <b-tag size="is-large" :class="ws.statusClass">{{
              ws.message
            }}</b-tag>
          </div>
        </div>
      </div>
      <div class="columns is-vcentered all-page">
        <div class="column center-content">
          <form @submit.prevent>
            <b-field label="PIN" custom-class="has-text-white-bis">
              <b-input
                v-model="pin"
                placeholder="Pin"
                type="password"
                :disabled="!nextActionAllowed || !activeNotifications"
              ></b-input>
            </b-field>
            <div class="buttons has-addons expanded">
              <b-button
                native-type="button"
                type="is-primary"
                style="flex: 1"
                :loading="!nextActionAllowed"
                :disabled="inactiveSockets.length === 0 || !pin"
                @click="activate(actions.ACTIVATE)"
                >{{ $t(`sign.label.ACTIVATE`) }}</b-button
              >
              <b-button
                native-type="button"
                type="is-primary"
                style="flex: 1"
                :loading="!nextActionAllowed"
                :disabled="activeSockets.length === 0 || !pin"
                @click="activate(actions.DEACTIVATE)"
                >{{ $t(`sign.label.DEACTIVATE`) }}</b-button
              >
            </div>
            <div class="buttons mt-3 center-content">
              <b-button
                native-type="button"
                type="is-primary"
                :disabled="activeNotifications"
                @click="activate(actions.START_NOTIFICATIONS)"
                >{{ $t(`sign.label.${actions.START_NOTIFICATIONS}`) }}</b-button
              >
              <b-button
                type="is-primary"
                native-type="button"
                :disabled="!allHaveStatus && !activeNotifications"
                @click="activate(actions.STOP_NOTIFICATIONS)"
                >{{ $t(`sign.label.${actions.STOP_NOTIFICATIONS}`) }}</b-button
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import {
  KUrls,
  socketStatusCodes,
  basicSocketActions,
  socketActions,
} from "@/helpers/constants";
import { authHeader } from "@/helpers/authHeader.js";
export default {
  props: {
    // classes: {
    //   type: Array,
    //   required: true,
    // },
  },
  data() {
    return {
      webSockets: [],
      pin: "",
      activeNotifications: true,
      actions: { ...socketActions },
    };
  },
  computed: {
    ...mapGetters({
      getCompanyClasses: "menu/getCompanyClasses",
    }),
    areSocketsReady() {
      for (let socket of this.webSockets) {
        if (!socket.status) {
          return false;
        }
      }
      return true;
    },
    areSocketsActivated() {
      for (let socket of this.webSockets) {
        if (!socket.status || socket.status !== socketStatusCodes.ACTIVATED) {
          return false;
        }
      }
      return true;
    },
    classes() {
      return this.getCompanyClasses(this.companyName);
    },
    companyName() {
      return this.$route.params.company;
    },
    allHaveNextAction() {
      for (let socket of this.webSockets) {
        if (!socket.nextActionAllowed) {
          return false;
        }
      }
      return true;
    },
    nextActionAllowed() {
      let nextAction = "";
      if (this.webSockets.length > 0) {
        // const actionOfFirst = this.webSockets[0].nextActionAllowed;
        // const allEquals = this.webSockets.every(
        //   (ws) => ws.nextActionAllowed === actionOfFirst
        // );
        // if (allEquals) {
        //   nextAction = actionOfFirst;
        // }
        const wsActiveIndex = this.webSockets.findIndex(
          (ws) => ws.nextActionAllowed === socketActions.DEACTIVATE
        );
        if (wsActiveIndex >= 0) {
          nextAction = socketActions.DEACTIVATE;
        } else {
          nextAction = socketActions.ACTIVATE;
        }
      }
      return nextAction;
    },
    activeSockets() {
      return this.webSockets.filter(
        (ws) => ws.nextActionAllowed === socketActions.DEACTIVATE
      );
    },
    inactiveSockets() {
      return this.webSockets.filter(
        (ws) => ws.nextActionAllowed === socketActions.ACTIVATE
      );
    },
    allOpen() {
      return this.webSockets.every(
        (ws) => ws.webSocket && ws.webSocket.readyState === WebSocket.OPEN
      );
    },
    allHaveStatus() {
      return this.webSockets.every((ws) => ws.status);
    },
  },
  methods: {
    activate: function (nextAction) {
      // console.log(this.webSocket);
      const action = nextAction ? nextAction : this.nextActionAllowed;
      // At this point this.nextActionAllowed should have a value but an additional check surely does not hurt
      if (action) {
        const msg = { action, pin: this.pin };
        switch (action) {
          case basicSocketActions.ACTIVATE_PARTIAL:
            this.$buefy.dialog.prompt({
              message: this.$t("sign.max_progressive_heading"),
              inputAttrs: {
                type: "number",
                placeholder: this.$t("sign.max_progressive_placeholder"),
                required: true,
              },
              trapFocus: true,
              onConfirm: (value) => {
                msg.progressive = value;
                this.sendMessageAll(msg);
              },
            });
            break;
          case socketActions.STOP_NOTIFICATIONS:
            this.sendMessageAll(msg);
            this.activeNotifications = false;
            break;
          case socketActions.START_NOTIFICATIONS:
            this.webSockets.forEach((ws) => (ws.status = ""));
            this.sendMessageAll(msg);
            this.activeNotifications = true;
            break;
          case socketActions.ACTIVATE: {
            let fs = this.webSockets.filter(
              (ws) => ws.nextActionAllowed === socketActions.ACTIVATE
            );
            this.sendMessage(msg, fs);
            break;
          }
          case socketActions.DEACTIVATE: {
            let fs = this.webSockets.filter(
              (ws) => ws.nextActionAllowed !== socketActions.ACTIVATE
            );
            this.sendMessage(msg, fs);
            break;
          }
          default:
            this.sendMessageAll(msg);
        }
        this.pin = "";
      }
    },
    sendMessageAll(msg) {
      const json = JSON.stringify(msg);
      this.webSockets.forEach((ws) => ws.webSocket.send(json));
    },
    sendMessage(msg, socketArray) {
      const json = JSON.stringify(msg);
      socketArray.forEach((ws) => ws.webSocket.send(json));
    },
  },
  mounted() {
    const token = authHeader();
    const urlConf = KUrls.web_socket;
    let urlProtocol = "";
    if (urlConf.PROTOCOL) {
      urlProtocol = urlConf.PROTOCOL;
    } else {
      urlProtocol = window.location.protocol === "https:" ? "wss" : "ws";
    }
    const originUrl = `${urlProtocol}://${
      urlConf.HOST ? urlConf.HOST : window.location.host
    }${urlConf.ENDPOINT}`;
    for (const clazz of this.classes) {
      const ws = {
        classId: clazz.id,
        className: clazz.name,
        classLabel: clazz.label ? clazz.label : clazz.name,
        message: this.$t("sign.status.waiting"),
        nextActionAllowed: "",
        status: "",
        statusClass: "",
      };
      ws.webSocket = new WebSocket(
        `${originUrl}${KUrls.MASSIVE_SIGN_SERVICE}/${this.companyName}/${ws.className}?token=${token}`,
        []
      );
      ws.webSocket.onerror = () => {
        // console.error(
        //   "WebSocket error observed for class " + clazz.name,
        //   event
        // );
      };

      ws.webSocket.onmessage = (event) => {
        console.log(event);
        var response = JSON.parse(event.data);
        const { status } = response;
        console.log(status);
        ws.status = status;
        ws.nextActionAllowed = response.nextActionAllowed;
        switch (status) {
          case socketStatusCodes.DEACTIVATED:
            ws.message = this.$t("sign.status.not-active");
            ws.statusClass = "is-warning";
            break;
          case socketStatusCodes.WRONG_PIN:
            ws.message = this.$t("sign.status.wrong-pin");
            ws.statusClass = "is-danger";
            break;
          case socketStatusCodes.UNAUTHORIZED_USER:
            ws.message = this.$t("sign.status.wrong-user");
            ws.statusClass = "is-danger";
            break;
          case socketStatusCodes.ACTIVATED:
            ws.message = this.$t("sign.status.active");
            ws.statusClass = "is-success";
            break;
          case socketStatusCodes.WRONG_STOP_PIN:
            ws.message = this.$t("sign.status.wrong-stop-pin");
            ws.statusClass = "is-danger";
            break;
          case socketStatusCodes.ACTIVATED_PARTIAL:
            ws.message = this.$t("sign.status.partially-active", {
              progressive: response.maxProgressive,
            });
            ws.statusClass = "is-success";
            break;
          default:
            console.log(event.data);
            ws.statusClass = "";
        }
        // onOpen - response: {"maxProgressive":0,"status":1,"fiscalYear":0}
      };

      ws.webSocket.onopen = () => {
        // console.log(event);
        // console.log(
        //   "Successfully connected to the websocket server for class " +
        //     clazz.name
        // );
      };

      ws.webSocket.onclose = () => {
        // console.log("WebSocket is closed now for class " + clazz.name, event);
      };

      this.webSockets.push(ws);
    }
  },
  beforeDestroy() {
    this.webSockets.forEach((ws) => ws.webSocket.close());
  },
};
</script>

<style scoped>
.center-content {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
}
</style>
